import i18next, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const supportedLanguages = ['en-US', 'sk-SK', 'de-DE'] as const;

export type Locale = typeof supportedLanguages[number];

interface Options {
  onInit: (t: TFunction) => void;
  onLanguageChange: (lng: string, t: TFunction) => void;
}

export const init = ({ onInit, onLanguageChange }: Options) => {
  return i18next
    .use(LanguageDetector)
    .use(backend)
    .use(initReactI18next)
    .init(
      {
        supportedLngs: [...supportedLanguages],
        fallbackLng: 'en-US',
        load: 'currentOnly',
        interpolation: {
          escapeValue: false
        },
        debug: process.env.NODE_ENV === 'development'
      },
      (_, t) => {
        i18next.on('languageChanged', (lng) => onLanguageChange(lng, t));
        onInit(t);
      }
    );
};
