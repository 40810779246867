// core
import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';

// scripts
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// config
import { init as initFirebase } from 'config/firebase';
import { init as initBugsnag } from 'config/bugsnag';
import { init as initMaterialUi } from 'config/material-ui';

// material-ui
import Box from '@material-ui/core/Box';

// components
import { Loading } from 'components/Loading';

// app
import App from './App';

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  initBugsnag(process.env.REACT_APP_BUGSNAG_API_KEY, process.env.REACT_APP_BUILD_NUMBER);
}

initFirebase();

initMaterialUi();

ReactDOM.render(
  <StrictMode>
    <Suspense
      fallback={
        <Box height="100vh" display="flex" flexDirection="column" alignItems="center">
          <Loading />
        </Box>
      }
    >
      <App />
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
