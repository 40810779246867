import React, { useMemo, CSSProperties } from 'react';

// libraries
import { BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider, SnackbarOrigin } from 'notistack';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

// apollo
import { ApolloProvider } from '@apollo/client';

// material-ui
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';

// config
import { init as initI18n, Locale } from 'config/i18n';
import { getMuiLocalization, getXLocalization, getTheme } from 'config/material-ui';
import { client } from 'config/api';

// components
import { Loading } from 'components/Loading';

import { PageRouter } from './PageRouter';

import { useReinitializeStoredToken } from 'utils/auth';
import { buildYupLocale } from 'utils/validation';

initI18n({
  onInit: (t) => {
    buildYupLocale(t);
  },
  onLanguageChange: (lng, t) => {
    buildYupLocale(t);
  }
});

const useStyles = makeStyles(({ mixins, spacing, zIndex }) => ({
  snackbarContainer: {
    zIndex: zIndex.appBar - 1,
    // reuse minHeight from theme for toolbar but use it for top coordinate
    ...Object.keys(mixins.toolbar)
      .reverse()
      .reduce(
        (styles, key) => ({
          ...(key === 'minHeight'
            ? { top: mixins.toolbar[key] }
            : { [key]: { top: (mixins.toolbar[key] as CSSProperties).minHeight } }),
          ...styles
        }),
        {}
      )
  }
}));

const ApolloRoot = ({ children }: any) => {
  const initialized = useReinitializeStoredToken();

  return !initialized ? (
    <Box height="100vh" display="flex" flexDirection="column" alignItems="center">
      <Loading />
    </Box>
  ) : (
    children
  );
};

const Content = () => {
  const css = useStyles();

  const snackbarAnchorOrigin: SnackbarOrigin = useMemo(
    () => ({
      vertical: 'top',
      horizontal: 'center'
    }),
    []
  );

  const snackbarClasses = useMemo(
    () => ({
      containerRoot: css.snackbarContainer
    }),
    [css]
  );

  const gtmParams = useMemo(() => ({ id: process.env.REACT_APP_GTM_KEY }), []);

  return (
    <GTMProvider state={gtmParams}>
      <SnackbarProvider anchorOrigin={snackbarAnchorOrigin} classes={snackbarClasses}>
        <Router>
          <PageRouter />
        </Router>
      </SnackbarProvider>
    </GTMProvider>
  );
};

function App() {
  const { i18n } = useTranslation();

  const muiLocalization = getMuiLocalization(i18n.language as Locale);
  const xLocalization = getXLocalization(i18n.language as Locale);
  const theme = getTheme(muiLocalization, xLocalization);

  return (
    <ApolloProvider client={client}>
      <ApolloRoot>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Content />
        </ThemeProvider>
      </ApolloRoot>
    </ApolloProvider>
  );
}

export default App;
