import * as yup from 'yup';
import { TFunction } from 'i18next';

/**
 * Build localization object for Yup
 */
export const buildYupLocale = (t: TFunction) => {
  yup.setLocale({
    mixed: {
      required: ({ path }: any) => ({ key: 'validation.required', values: { path } })
    },
    string: {
      email: ({ path }: any) => ({ key: 'validation.email', values: { path } }),
      min: ({ path, min }: any) => ({ key: 'validation.minLength', values: { path, min } })
    }
  });
};
