// core
import React, { Suspense, lazy, useEffect, useRef } from 'react';

// libraries
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// apollo
import { useReactiveVar } from '@apollo/client';

// components
import { Loading } from 'components/Loading';

// layouts
import { PageLayout } from 'layouts/PageLayout';

// config
import { loggedInUserId } from 'config/cache';

// scripts
import reportWebVitals from './reportWebVitals';

// pages
const Register = lazy(() => import('pages/Auth/Register'));
const Login = lazy(() => import('pages/Auth/Login'));
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'));
const VerificationRequest = lazy(() => import('pages/VerificationRequest/VerificationRequest'));
const Advertisements = lazy(() => import('pages/Advertisements/Advertisements'));
const Profile = lazy(() => import('pages/Profile/Profile'));
const Error404 = lazy(() => import('pages/Error404/Error404'));

export const PageRouter = () => {
  const history = useHistory();
  const location = useLocation<{ referrer?: string } | undefined>();
  const sendDataToGTM = useGTMDispatch();
  const userId = useReactiveVar(loggedInUserId);
  const { current: initialUserId } = useRef<string | null>(userId || null);

  // send initial page load event
  useEffect(() => {
    sendDataToGTM({ event: 'pageview', userId: initialUserId });
  }, [sendDataToGTM, initialUserId]);

  // send every history change as virtual pageview
  useEffect(() => {
    history.listen((location) => {
      sendDataToGTM({ event: 'pageview', userId });
    });
  }, [history, sendDataToGTM, userId]);

  useEffect(() => {
    reportWebVitals(({ id, name, value }) => {
      sendDataToGTM({
        event: 'webVitals',
        eventCategory: 'Web Vitals',
        eventAction: name,
        eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
        eventLabel: id, // id unique to current page load
        userId: initialUserId
      });
    });
  }, [sendDataToGTM, initialUserId]);

  return (
    <PageLayout>
      <Suspense fallback={<Loading />}>
        {!userId ? (
          <Switch>
            {/* public routes */}
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset-password" component={ResetPassword} />

            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: location.pathname }
              }}
            />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" component={Advertisements} />
            <Route exact path="/profile/:section?" component={Profile} />
            <Route exact path="/verification-request" component={VerificationRequest} />

            {/* redirect after login */}
            <Route exact path={['/register', '/login']}>
              <Redirect to={location.state?.referrer || '/'} />
            </Route>

            {/* default fallback */}
            <Route component={Error404} />
          </Switch>
        )}
      </Suspense>
    </PageLayout>
  );
};
