// core
import React from 'react';

// material-ui
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Loading = () => {
  return (
    <Box
      flex={1}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};
