/**
 * Formats number to human readable string
 */
export const formatNumber = (value: number): string => {
  const numberFormatter = new Intl.NumberFormat('sk-SK');

  return numberFormatter.format(value);
};

/**
 * Formats price value in euros
 */
export const formatPrice = (value: number, currency: string = 'EUR', numDecimals = 2): string => {
  const currencyFormatter = new Intl.NumberFormat('sk-SK', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals
  });

  return currencyFormatter.format(value);
};

/**
 * Extracts first letters of words from given string
 */
export const extractInitials = (text: string, numberOfChars: number = 3) => {
  return text
    .split(' ')
    .slice(0, numberOfChars)
    .map((word: string) => word[0])
    .join('');
};
