// core
import { useState, useEffect, useCallback } from 'react';

// apollo
import { loggedInUserId } from 'config/cache';
import { useApolloClient } from '@apollo/client';

// firebase
import firebase from 'firebase/app';
import 'firebase/auth';

export const getCurrentUser = () => {
  return firebase.auth().currentUser;
};

export const getCurrentUserToken = (): Promise<string | null> => {
  const currentUser = getCurrentUser();

  return currentUser?.getIdToken() || Promise.resolve(null);
};

export const storeCurrentUserId = (user: firebase.User | null) => {
  if (!user) {
    return Promise.reject(new Error('Not logged in'));
  }

  return user.getIdTokenResult().then((result) => {
    loggedInUserId(result.claims._id);
  });
};

export const useLogout = (): (() => Promise<void>) => {
  const client = useApolloClient();

  const logout = useCallback(
    () =>
      firebase
        .auth()
        .signOut()
        .then(() =>
          client.clearStore().then(() => {
            loggedInUserId(null);
          })
        ),
    [client]
  );

  return logout;
};

export const useReinitializeStoredToken = () => {
  const logout = useLogout();

  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    firebase.auth().onIdTokenChanged((user) => {
      const promise: Promise<any> = user ? storeCurrentUserId(user) : logout();

      promise.then(() => {
        setInitialized(true);
      });
    });
  }, [logout]);

  return initialized;
};
