// material-ui
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { enUS, skSK, deDE, Localization as MuiLocalization } from '@material-ui/core/locale';
import {
  LicenseInfo,
  enUS as xenUS,
  skSK as xskSK,
  deDE as xdeDE,
  Localization as XLocalization
} from '@material-ui/x-grid';

// i18n
import { Locale } from './i18n';

const muiLocaleMap: { [key in Locale]: MuiLocalization } = {
  'en-US': enUS,
  'sk-SK': skSK,
  'de-DE': deDE
};

const xLocaleMap: { [key in Locale]: XLocalization } = {
  'en-US': xenUS,
  'sk-SK': xskSK,
  'de-DE': xdeDE
};

export const init = () => {
  if (process.env.REACT_APP_MUI_LICENSE_KEY) {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
  }
};

export const getMuiLocalization = (locale: Locale) => {
  return muiLocaleMap[locale];
};

export const getXLocalization = (locale: Locale) => {
  return xLocaleMap[locale];
};

export const getTheme = (muiLocalization: MuiLocalization, xLocalization: XLocalization) => {
  const theme = createMuiTheme(
    {
      palette: {
        primary: {
          main: '#2095f2'
        },
        secondary: {
          main: '#0a2c48'
        }
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              overflow: 'hidden'
            }
          }
        }
      }
    },
    muiLocalization || enUS,
    xLocalization || xenUS
  );

  return responsiveFontSizes(theme);
};
