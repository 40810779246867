// core
import React from 'react';

// libraries
import classnames from 'classnames';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';

// utils
import { extractInitials } from '../../utils/format';

export type AvatarSize = 'medium' | 'large';

export interface UserAvatarProps extends Omit<AvatarProps, 'children'> {
  /**
   * Full name of app user
   */
  fullName?: string;
  /**
   * Size of the avatar
   */
  size?: AvatarSize;
}

const useStyles = makeStyles(({ spacing }) => ({
  medium: {
    width: spacing(5),
    height: spacing(5)
  },
  large: {
    width: spacing(10),
    height: spacing(10),
    fontSize: spacing(4)
  }
}));

export const UserAvatar = ({ fullName, size = 'medium', className, ...props }: UserAvatarProps) => {
  const css = useStyles();

  return (
    <Avatar {...props} className={classnames(css[size], className)}>
      {fullName && extractInitials(fullName, 2)}
    </Avatar>
  );
};
