// core
import React from 'react';

// libraries
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material ui
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import InfoIcon from '@material-ui/icons/InfoOutlined';
import UnpublishedIcon from '@material-ui/icons/UnpublishedOutlined';

// components
import { WarningButton } from 'components/WarningButton';

export const NotVerifiedAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert
      severity="warning"
      icon={<UnpublishedIcon />}
      action={
        <Box clone textAlign="center">
          <WarningButton
            variant="contained"
            size="small"
            component={Link}
            to="/verification-request"
          >
            {t('requestVerification')}
          </WarningButton>
        </Box>
      }
    >
      <Box display="flex" alignItems="center">
        {t('accountNotVerifiedYet')}

        <Box clone marginLeft={0.5}>
          <Tooltip title={t('notAllowedToSeeDetails').toString()}>
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Box>
      </Box>
    </Alert>
  );
};
