// core
import React, { ReactNode } from 'react';

// libraries
import { useLocation } from 'react-router-dom';

// material-ui
import Box from '@material-ui/core/Box';

// components
import { Header } from 'components/Header';

export interface PageLayoutProps {
  /**
   * Content to diplay on the page
   */
  children: ReactNode;
}

export const PageLayout = ({ children }: PageLayoutProps) => {
  const location = useLocation();

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Header currentPath={location.pathname} />

      <Box id="content" flex={1} display="flex" flexDirection="column" overflow="auto">
        {children}
      </Box>
    </Box>
  );
};
