// core
import React, { useCallback } from 'react';

// libraries
import { useTranslation } from 'react-i18next';

// material-ui
import Divider from '@material-ui/core/Divider';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';

// components
import { UserAvatar } from '../../UserAvatar/UserAvatar';

// utils
import { useLogout } from 'utils/auth';

// partials
import { UserMenuItem } from './UserMenuItem';

export interface UserMenuProps {
  /**
   * User name
   */
  fullName: string | null;
  /**
   * User email
   */
  email: string | null;
  /**
   * Callback to run on click on any item
   */
  onItemClick: () => void;
}

export const UserMenu = ({ fullName, email, onItemClick }: UserMenuProps) => {
  const { t } = useTranslation();

  const logout = useLogout();

  const handleLogout = useCallback(() => {
    logout();
    onItemClick();
  }, [logout, onItemClick]);

  return (
    <>
      {fullName && email && (
        <>
          <CardHeader
            avatar={<UserAvatar fullName={fullName} />}
            title={fullName}
            subheader={email}
          />

          <Divider />
        </>
      )}

      <List>
        <UserMenuItem to="/profile" label={t('myProfile')} onClick={onItemClick}></UserMenuItem>

        <Divider />

        <UserMenuItem label={t('actions.logout')} onClick={handleLogout}></UserMenuItem>
      </List>
    </>
  );
};
