import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export const init = (apiKey: string, appVersion?: string): void => {
  Bugsnag.start({
    apiKey,
    appType: 'client',
    appVersion,
    plugins: [new BugsnagPluginReact()]
  });
};
