// core
import React from 'react';

// libraries
import { Link } from 'react-router-dom';

// material
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export interface HeaderMenuItemProps {
  /**
   * Link form menu item
   */
  to: string;
  /**
   * Whether it is currently active
   */
  selected?: boolean;
  /**
   * Content of menu item
   */
  children: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover, &:active,  &:focus': {
      background: 'none',
      textDecoration: 'underline'
    },
    '&:last-child': {
      paddingRight: 0
    }
  }
}));

export const HeaderMenuItem = ({ to, children, selected }: HeaderMenuItemProps) => {
  const classes = useStyles();

  return (
    <Button
      component={Link}
      to={to}
      variant="text"
      size="medium"
      disableRipple
      className={classes.root}
      color={selected ? 'primary' : 'default'}
    >
      {children}
    </Button>
  );
};
