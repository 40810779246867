// core
import React from 'react';

// libraries
import { Link } from 'react-router-dom';

// material-ui
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export interface UserMenuItemProps {
  /**
   * Link form menu item
   */
  to?: string;
  /**
   * Label of menu
   */
  label: string;
  /**
   * Response on click
   */
  onClick: any;
}

export const UserMenuItem = ({ to, label, onClick }: UserMenuItemProps) => {
  return (
    <ListItem button component={to ? Link : 'div'} to={to} onClick={onClick}>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  );
};
