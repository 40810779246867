// apollo
import { ApolloClient, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink } from 'apollo-upload-client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';

// libraries
import { sha256 } from 'crypto-hash';
import Bugsnag, { Event } from '@bugsnag/js';

// config
import { cache } from './cache';

// utils
import { getCurrentUserToken } from 'utils/auth';

const options = {
  uri: process.env.REACT_APP_API_URL
};

const authorizationLink = setContext((request, previousContext) => {
  return getCurrentUserToken().then((token) => ({
    headers: { authorization: token ? `Bearer ${token}` : '' }
  }));
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, path } = error;

      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
      Bugsnag.notify(error.originalError || new Error(message), (event: Event) =>
        event.addMetadata('error', error)
      );
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const persistedQueryLink = createPersistedQueryLink({ sha256 });
const uploadLink = createUploadLink(options);
const retryLink = new RetryLink();
const batchLink = new BatchHttpLink(options);

const isObject = (node: any) => typeof node === 'object' && node !== null;

const hasFiles = (node: { [key: string]: any }, found: any[] = []) => {
  Object.keys(node).forEach((key) => {
    if (!isObject(node[key]) || found.length > 0) {
      return;
    }

    if (
      (typeof File !== 'undefined' && node[key] instanceof File) ||
      (typeof Blob !== 'undefined' && node[key] instanceof Blob)
    ) {
      found.push(node[key]);
      return;
    }

    hasFiles(node[key], found);
  });

  return found.length > 0;
};

export const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authorizationLink,
    ApolloLink.split(
      ({ variables }) => hasFiles(variables),
      uploadLink,
      persistedQueryLink.concat(retryLink).concat(batchLink)
    )
  ]),
  cache
});
