// core
import React, { ElementType } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';

// colors
import { orange } from '@material-ui/core/colors';

export type WarningButtonProps<T extends ElementType<any>> = ButtonProps<T>;

const useStyles = makeStyles(({ palette }) => ({
  text: {
    color: orange[500]
  },
  contained: {
    color: palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700]
    }
  },
  outlined: {
    color: orange[500],
    borderColor: orange[500]
  }
}));

export const WarningButton = <T extends ElementType<any>>({
  classes,
  ...props
}: WarningButtonProps<T>) => {
  const css = useStyles();

  return <Button {...props} classes={{ ...css, ...classes }} />;
};
