import { InMemoryCache, makeVar } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export const loggedInUserId = makeVar<string | undefined | null>(undefined);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loggedInUserId: {
          read() {
            return loggedInUserId();
          }
        },

        cars: relayStylePagination(/* ["filter"] */)
      }
    }
  }
});
