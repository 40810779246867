// core
import React, { memo, ReactElement, useCallback, useMemo, useState, MouseEvent } from 'react';

// libraries
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// apollo
import { useQuery, useReactiveVar } from '@apollo/client';
import getUserQuery from '../../graphql/User/GetUserQuery.graphql';
import { GetUserQuery, GetUserQueryVariables } from 'graphql/User/GetUserQuery';

// material-ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';

// icons
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';
import LoginIcon from '@material-ui/icons/LoginOutlined';
import RegisterIcon from '@material-ui/icons/PersonAddOutlined';
import ProfileIcon from '@material-ui/icons/AccountCircleOutlined';
import ProfileVerificationIcon from '@material-ui/icons/HowToRegOutlined';
import AdvertisementsIcon from '@material-ui/icons/CommuteOutlined';

// components
import { UserAvatar } from '../UserAvatar';
import { NotVerifiedAlert } from '../NotVerifiedAlert';
import { NotVerifiedCallout } from '../NotVerifiedCallout';

// config
import { loggedInUserId } from 'config/cache';

// partials
import { HeaderMenuItem } from './partials/HeaderMenuItem';
import { UserMenu } from './partials/UserMenu';

// assets
import logo from '../../images/auto-sklad-logo.svg';

export interface HeaderProps {
  /**
   * Current location path without query string
   */
  currentPath: string;
}

interface IMenuRoute {
  desktopOnly?: boolean;
  icon?: ReactElement;
  mobileOnly?: boolean;
  selected?: boolean;
  title: string;
  to: string;
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    transition: 'height .2s'
  },
  logo: {
    height: 30,
    display: 'block'
  },
  menuWrap: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  menu: {
    flex: 1,
    textAlign: 'center'
  },
  phoneWrap: {
    textAlign: 'right',
    marginRight: 4
  },
  phoneWrapInDrawer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  phone: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 4
    }
  },
  menuButton: {
    marginLeft: theme.spacing(2)
  },
  drawerPaper: {
    width: 300,
    maxWidth: '90%'
  },
  userMenu: {
    height: 48,
    marginLeft: theme.spacing(2)
  },
  userMenuHeading: {
    '&:focus': {
      outline: 'none'
    }
  },
  map: {
    width: '100%',
    height: '40%',

    border: 0
  },
  mobileMenuItemText: {
    paddingLeft: 0
  },
  userName: {
    fontWeight: 'bold'
  },
  facebook: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& > img': {
      marginRight: theme.spacing(1)
    }
  },
  userAvatar: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export const Header = memo(function Header({ currentPath }: HeaderProps) {
  const css = useStyles();
  const { t } = useTranslation();

  const userId = useReactiveVar(loggedInUserId);

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(null);

  const { data, loading } = useQuery<GetUserQuery, GetUserQueryVariables>(getUserQuery, {
    variables: {
      id: userId || ''
    },
    skip: !userId
  });

  const user = data?.user || null;
  const isBusinessVerified =
    !!user?.business && user?.business.verificationStatus !== 'NOT_VERIFIED';

  const handleUserMenuOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(event.currentTarget);
  }, []);

  const handleUserMenuClose = useCallback(() => {
    setUserMenuAnchor(null);
  }, []);

  const handleMobileMenuToggle = useCallback(() => {
    setMobileOpen((isMobileOpen) => !isMobileOpen);
  }, []);

  const menuRoutesPublic: IMenuRoute[] = [
    {
      icon: <LoginIcon />,
      title: t('login'),
      to: '/login'
    },
    {
      icon: <RegisterIcon />,
      title: t('registerDealer'),
      to: '/register'
    }
  ];

  const menuRoutesProtected: IMenuRoute[] = [
    {
      icon: <AdvertisementsIcon />,
      title: t('advertisements'),
      selected: currentPath === '/',
      to: '/'
    },
    {
      icon: <ProfileIcon />,
      title: t('profile'),
      to: '/profile'
    },
    data &&
      !isBusinessVerified && {
        icon: <ProfileVerificationIcon />,
        title: t('profileVerification'),
        to: '/verification-request'
      }
  ].filter(Boolean) as IMenuRoute[];

  const menuRoutes: IMenuRoute[] = (userId ? menuRoutesProtected : menuRoutesPublic).map((route) =>
    typeof route.selected === 'boolean'
      ? route
      : {
          ...route,
          selected: currentPath.startsWith(route.to)
        }
  );

  const desktopMenuItems = useMemo(
    () =>
      menuRoutes
        .filter((route) => !route.mobileOnly)
        .map((route) => (
          <HeaderMenuItem key={route.to} to={route.to} selected={route.selected}>
            {route.title}
          </HeaderMenuItem>
        )),
    [menuRoutes]
  );

  const mobileMenuItems = useMemo(
    () =>
      menuRoutes
        .filter((route) => !route.desktopOnly)
        .map((route) => (
          <ListItem
            key={route.to}
            button
            component={Link}
            to={route.to}
            onClick={handleMobileMenuToggle}
            selected={route.selected}
          >
            <ListItemIcon>{route.icon}</ListItemIcon>
            <ListItemText primary={route.title} className={css.mobileMenuItemText} />
          </ListItem>
        )),

    [menuRoutes, css.mobileMenuItemText, handleMobileMenuToggle]
  );

  return (
    <AppBar position="static" color="inherit" id="page-header">
      <Container maxWidth={false}>
        <Toolbar className={css.toolbar}>
          <Link to="/">
            <img src={logo} alt="Auto Sklad logo" className={css.logo} />
          </Link>

          {data && !isBusinessVerified && (
            <Hidden smDown>
              <Box marginLeft={4}>
                <NotVerifiedAlert />
              </Box>
            </Hidden>
          )}

          <div className={css.menuWrap}>
            <Hidden smDown={isBusinessVerified} mdDown={!isBusinessVerified}>
              <nav className={css.menu}>{desktopMenuItems}</nav>

              {loading ? (
                <Avatar>
                  <CircularProgress />
                </Avatar>
              ) : (
                userId && (
                  <UserAvatar
                    data-testid="header-user-avatar"
                    fullName={user?.fullName}
                    className={css.userAvatar}
                    onClick={handleUserMenuOpen}
                  />
                )
              )}
            </Hidden>

            {userId && (
              <Hidden smDown>
                <Popover
                  id="user-menu"
                  anchorEl={userMenuAnchor}
                  keepMounted
                  open={Boolean(userMenuAnchor)}
                  onClose={handleUserMenuClose}
                >
                  <UserMenu
                    fullName={user?.fullName || null}
                    email={user?.email || null}
                    onItemClick={handleUserMenuClose}
                  />
                </Popover>
              </Hidden>
            )}

            <Hidden mdUp={isBusinessVerified} lgUp={!isBusinessVerified}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleMobileMenuToggle}
                className={css.menuButton}
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                id="main-drawer"
                variant="temporary"
                anchor="right"
                open={mobileOpen}
                onClose={handleMobileMenuToggle}
                classes={{
                  paper: css.drawerPaper
                }}
              >
                <Box paddingTop={2} paddingLeft={2} display="flex" alignItems="center">
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="back"
                    onClick={handleMobileMenuToggle}
                  >
                    <BackIcon />
                  </IconButton>

                  <Box clone marginBottom={1} marginLeft={0.5}>
                    <Link to="/">
                      <img src={logo} alt="Auto Sklad logo" className={css.logo} />
                    </Link>
                  </Box>
                </Box>

                <Box flex={1}>
                  <nav>
                    <List>{mobileMenuItems}</List>
                  </nav>

                  {!isBusinessVerified && (
                    <Box marginTop={2} marginBottom={2} paddingLeft={2} paddingRight={2}>
                      <NotVerifiedCallout />
                    </Box>
                  )}
                </Box>

                {userId && (
                  <UserMenu
                    fullName={user?.fullName || null}
                    email={user?.email || null}
                    onItemClick={handleMobileMenuToggle}
                  />
                )}
              </Drawer>
            </Hidden>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
});
