// core
import React from 'react';

// libraries
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// icons
import UnpublishedIcon from '@material-ui/icons/UnpublishedOutlined';

// colors
import { orange } from '@material-ui/core/colors';

// components
import { WarningButton } from 'components/WarningButton';

const useStyles = makeStyles(({ palette }) => ({
  paper: {
    backgroundColor: orange[50]
  },
  title: {
    color: '#663c00'
  },
  icon: {
    color: palette.warning.main,
    fontSize: 32
  }
}));

export const NotVerifiedCallout = () => {
  const { t } = useTranslation();
  const css = useStyles();

  return (
    <Box
      clone
      textAlign="center"
      paddingLeft={3}
      paddingRight={3}
      paddingTop={4}
      paddingBottom={4}
      maxWidth={400}
    >
      <Paper elevation={0} className={css.paper}>
        <UnpublishedIcon className={css.icon} />

        <Typography color="inherit" variant="h6" component="h2" gutterBottom className={css.title}>
          {t('accountNotVerifiedYet')}
        </Typography>

        <Typography color="textSecondary" variant="subtitle2" component="p" gutterBottom>
          {t('notAllowedToSeeDetails')}
        </Typography>

        <Box clone marginTop={2}>
          <WarningButton variant="contained" component={Link} to="/verification-request">
            {t('requestVerificationNow')}
          </WarningButton>
        </Box>
      </Paper>
    </Box>
  );
};
